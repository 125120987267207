import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenfranco/Documents/dev/medgrocer/pp-jn-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import Collapsible from "elements/Collapsible";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Terms and Conditions" mdxType="SEO" />
      <h2>{`Terms and Conditions`}</h2>
      <hr />
      <div className="content is-medium">
        <h4>{`General`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">{`careplus.medgrocer.com (“Website”) is an internet-based platform that facilitates the online sale of the pharmaceutical products of Johnson and Johnson Philippines Inc. (“J&J Philippines”) by MG Health Solutions Inc. (“MedGrocer”), a company duly authorized by the Food and Drug Administration (FDA) of the Philippines.`}</li>
          </ol>
        </span>
        <h4>{`CarePlus Program`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">{`To avail of the benefits of the CarePlus Program (“Program”), the patient should enroll by accomplishing the online Patient Enrollment Form. The patient will receive an SMS confirming his/her eligibility to the Program. Eligibility to the Program is subject to approval by MedGrocer.`}</li>
            <li parentName="ol">{`The consent of the attending doctor is required for the enrollment of patients to the Program. Membership is not transferable.`}</li>
            <li parentName="ol">{`CarePlus should ensure that all drugs dispensed under the Program should have a corresponding prescription. Prescription date should be no more than one year from the order date and the remaining quantity indicated in the prescription should not be fully served.`}</li>
            <li parentName="ol">{`By having accepted the Terms and Conditions of the Program, the patient understands and acknowledges that the information disclosed in the Patient Enrollment Form as well as information obtained in the course of the Program are collected, processed, and stored in a database which shall be used and administered solely by MedGrocer and its partners. The patient likewise agrees that the information referred to in this section may be disclosed to the patient’s attending doctor, MedGrocer, MedGrocer’s partners, and third parties, pursuant strictly to the terms of the Patient Consent Form submitted by patient upon registration and enrollment with MedGrocer and specifically for the purposes indicated therein, which includes monitoring and following up on patient compliance with the prescription as well as implementation of and improving the Program.`}</li>
            <li parentName="ol">{`Any patient-specific information may not be used in any form of publication or promotional material without prior written approval by the patient. Anonymized and aggregate patient data may be analyzed and used to provide basis to further improve the Program.`}</li>
            <li parentName="ol">{`By availing of the benefits granted by the Program and in accordance with the mode of communication expressly stipulated in the Patient Consent Form, the patient consents to receiving emails, calls, text, and/or instant messages from MedGrocer. The patient may withdraw such consent at any time upon notification to MedGrocer and its partners.`}</li>
            <li parentName="ol">{`MedGrocer and its partners shall not be responsible nor liable to the patient for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations pursuant to these Terms and Conditions due to any mechanical, data processing, telecommunications failure, act of God, civil disturbance, any force majeure event outside MedGrocer’s and its partners’ control, or as a consequence of any fraud or forgery not attributable to MedGrocer or its partners; and B) any damage to, loss of, or inability to retrieve any data that may be stored in the MedGrocer database, to the extent allowed by law and applicable regulations; provided that such damage to, loss of, or inability to retrieve any such data is not attributable to any act, omission or incident of negligence on the part of MedGrocer.`}</li>
            <li parentName="ol">{`To the extent allowed by law and applicable regulations, MedGrocer and its partners may verify the identity of prescribing doctors indicated in the Patient Enrollment Form. In the event that a prescribing doctor or the information relating to the prescribing doctor is found to be fictitious or false, MedGrocer reserves the right to cancel the patient’s membership to the Program including all its benefits.`}</li>
            <li parentName="ol">{`The benefits of the Program may be availed of for the duration that MedGrocer offers the same. MedGrocer may opt to stop or continue the Program, in full or in part, at any time at its own discretion. MedGrocer also reserves the right to change the Program, its mechanics and benefits, and these Terms and Conditions, in full or in part, any time.`}</li>
          </ol>
        </span>
        <h4>{`Content`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">{`The contents of the Website, information, text, graphics, images, logos, icons, design, and the collection, arrangement, and assembly of content on the Website (collectively, “MedGrocer Content”), are the property of MedGrocer. The user shall not modify the MedGrocer Content or reproduce, display, publicly perform, distribute, or otherwise use the MedGrocer content in any way for any public or commercial purpose or for personal gain.`}</li>
            <li parentName="ol">{`MedGrocer Content may communicate basic information about medicines, supplements, pharmaceuticals, and other personal or healthcare products that may not be complete, up-to-date, or free from errors or omissions. MedGrocer may make changes or improvements at any time.`}</li>
            <li parentName="ol">{`The content of the Website, including without limitation, text, copy, audio, video, photographs, illustrations, graphics, and other visuals, is for informational purposes only and is not intended to be used as a substitute or replacement for professional medical advice, diagnosis, treatment, or recommendations of any kind. Individual medical concerns should be consulted with a qualified healthcare professional before taking any form of treatment, medication, or supplements. Reliance on any information appearing on the Website is solely at the user’s own risk.`}</li>
            <li parentName="ol">{`MedGrocer advises the user to always seek the advice of a doctor or any qualified healthcare professional with any questions regarding personal health or medical conditions. The user should never disregard, avoid, or delay obtaining medical advice from a doctor or other qualified healthcare professional because of information obtained from the Website or from MedGrocer representatives.`}</li>
            <li parentName="ol">{`Retail prices on the Website are subject to change without prior notice. All prices are inclusive of Value Added Tax (VAT) and are expressed in Philippine Peso. The user recognizes that pricing errors may occur and it is the responsibility of the user to review product prices during order confirmation.`}</li>
            <li parentName="ol">{`MedGrocer Content is provided “as is” and without warranties of any kind either expressed or implied, to the fullest extent permissible pursuant to applicable law. MedGrocer does not warrant or make any representations regarding the use of or the result of the use of the content of the Website in terms of their correctness, accuracy, reliability, or otherwise. MedGrocer also disclaims all liability resulting to loss, damage, personal injury, death, fraud, or other consequences arising from the use of the Website or services offered by MedGrocer.`}</li>
          </ol>
        </span>
        <h4>{`Order and Delivery`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">
              <p className="is-size-6">
  Order Placement <br />
  To place an order through the Website, the user must log-in using their activated
  credentials and complete the checkout process.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Order Processing <br />
  Orders are processed from Monday to Saturday from 8:30AM to 4:30PM only. Orders
  placed beyond this schedule and during public holidays are processed on the
  next working day. All orders received by MedGrocer are reviewed by a MedGrocer
  pharmacist.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Order Confirmation <br />
  After Order Processing, the user can expect an SMS or email from a MedGrocer
  pharmacist to confirm order summary. MedGrocer shall not be obliged to dispense
  any products until all the necessary requirements are complete.
              </p>
              <p className="is-size-6">
  Order Processing includes prescription authentication. The user must have a
  prescription from a registered medical practitioner, a copy of which must
  be uploaded to the Website and presented at the time of delivery.
  Prescription products can only be dispensed after a prescription given by a
  registered medical practitioner has been presented. MedGrocer maintains a
  record of all the prescriptions provided by the user which can be accessed
  by such user at request and may also be used to reorder the prescribed
  medicines.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Service Refusal <br />
  MedGrocer reserves the right, in its sole discretion, without limitations and/or
  conditions, to refuse any service to any user who does not comply with the program
  requirements and mechanics and for any reason attributable to the unavailability
  of the products ordered or other circumstances and conditions beyond the control
  of MedGrocer which would delay or render impossible the fulfilment of its obligations.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Delivery Schedule <br />
  From the time of successful Order Verification, the user may expect to receive the purchased products from MedGrocer within the one to two working days for Metro Manila and three to seven working days for key cities covered outside Metro Manila for non-cold chain products. Orders made through other dispensing channels will be delivered based on the dispensing channel’s delivery schedule. Inclement weather, natural calamities, and other events beyond MedGrocer’s control may affect the delivery schedule.
              </p>
              <p className="is-size-6">
  Upon successful delivery of the purchased products, the user should expect
  to receive an SMS or email from a MedGrocer representative to confirm and
  acknowledge that the products have been received by the intended patient.
  Patient is expected to expressly acknowledge receipt of the purchased
  products by replying to the SMS or email received from MedGrocer.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Delivery Area Coverage <br />
  MedGrocer delivers all products to Metro Manila and non-cold chain products
  to key cities outside of Metro Manila.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Delivery Receipt <br />
  Purchased products are dispensed by MedGrocer in sealed packages prior to delivery.
  Upon receipt of the package, it is the responsibility of the user or the authorized
  representative to inspect the packaging to ensure that it has not been tampered
  with. To acknowledge receipt of the package, the user or the authorized representative
  shall be asked to sign a delivery receipt. By signing the delivery receipt,
  the user or the authorized representative attests that the package received
  has no issues.
              </p>
            </li>
            <li parentName="ol">
              <p className="is-size-6">
  Cancellation <br />
  If the user wishes to cancel an order prior to dispensing, a notice must be
  sent to MedGrocer. MedGrocer, however, reserves the right to refuse cancellation
  in the case that the order has already been dispensed or dispatched for delivery
  prior to the receipt of the user’s request to cancel.
              </p>
            </li>
          </ol>
        </span>
        <h4>{`Account`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">{`Information that uniquely identifies the user (“Personal Information”) will be collected to process order. The user’s full name, email address, and mobile number are required. MedGrocer may also collect other Personal Information, including but not limited to delivery address, date of birth, and gender. MedGrocer will only be able to collect Personal Information voluntarily submitted. If the user chooses not to submit or subsequently withdraw consent to use the Personal Information, MedGrocer may not be able to provide the user with MedGrocer’s services.`}</li>
            <li parentName="ol">{`If the user provides the information of any third party to MedGrocer, the user represents that the user obtained the requisite consent from said third party and MedGrocer will act on such representation. Users ordering on behalf of minors and incapacitated patients may be required to submit proof of legal guardianship.`}</li>
            <li parentName="ol">{`Any accounts registered with someone else’s Personal Information may be closed without notice. MedGrocer reserves the right to collect documentation from the user to verify any information submitted if it is believed that the user has been using invalid Personal Information.`}</li>
            <li parentName="ol">{`The user must keep the account details private. The user agrees that any person to whom the username or password is disclosed to is authorized to act as the user’s agent for the purpose of using and transacting via the user’s account. The user is entirely responsible for the confidentiality of the account information.`}</li>
          </ol>
        </span>
        <h4>{`Limitation of Liability`}</h4>
        <span className='is-size-6'>
          <ol>
            <li parentName="ol">{`MedGrocer and its partners shall not be responsible or liable in any manner to the user for any losses, damage, injuries, or expenses incurred by the user as a result of any actions or decisions taken while using the Website or services offered by MedGrocer.`}</li>
            <li parentName="ol">{`In no event shall MedGrocer, its partners, or any of its’ directors, officers, employees, agents, or content or service providers be liable for any direct, indirect, special, incidental, consequential, exemplary, or punitive damages arising from, directly or indirectly related to the use of, or the inability to use the Website or the content, materials, and functions relations thereto and user’s provision of information via the Website. In no event shall such be liable for lost business or lost sales, even if there has been advice of the possibility of such damages. In no event shall MedGrocer and its partners be liable for any content posted, transmitted, exchanged, or received by or on behalf of any user or other person on or through the Website.`}</li>
            <li parentName="ol">{`Products on MedGrocer `}<br />{`
MedGrocer follows the stringent requirements of the Food and Drug Administration (FDA) in terms of storage, handling, and dispensing of the products. MedGrocer and its partners are therefore not liable for any damages (e.g., side effects, adulteration) related to the use of any products offered.`}</li>
            <li parentName="ol">{`Third-party Websites `}<br />{`
The Website may be linked to the website of third parties, affiliates, and business partners. MedGrocer has no control over and accepts no responsibility for the content of any site to which a link from the Website exists. Such linked sites are provided “as is” for the user’s convenience with no warranties, express or implied, for the information provided within them. Inclusion of any link on the Website does not imply that MedGrocer endorses the linked site. The user may use the links and these services at the user’s own risk. The user must not, without permission from MedGrocer, frame any of the Website onto another website.`}</li>
          </ol>
        </span>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      